import { render, staticRenderFns } from "./App.vue?vue&type=template&id=23f18dcf&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=23f18dcf&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f18dcf",
  null
  
)

export default component.exports